'use client'
import Button from '@/components/button'
import { ViewIcon } from 'ui/src/icons/utility'

type Props = {
    label: string
    href: string
}

const OpenInCurationStation = ({ label, href }: Props) => {
    return (
        <Button
            className="br-4px flex max-w-[133px] cursor-pointer items-center gap-2 rounded"
            href={href}
            target="_blank"
        >
            <div>{label}</div>
            <ViewIcon height={16} width={16} />
        </Button>
    )
}

export default OpenInCurationStation
