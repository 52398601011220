import { giphyBlue, giphyIndigo } from '@giphy/colors'
import { SetStateAction } from 'react'
import styled from 'styled-components'
import { FunnelIcon } from 'ui/src/icons/utility'
import { SectionHeader } from '@/components/sections'

const FilterButton = styled.div`
    display: flex;
    svg {
        #gradient-stop-1 {
            stop-color: ${giphyIndigo};
        }
        #gradient-stop-2 {
            stop-color: ${giphyBlue};
        }
    }
`

type Props = {
    label: string
    onClick: React.Dispatch<SetStateAction<boolean>>
    isLoggedIn: boolean
}

export const GridHeader = ({ label, onClick, isLoggedIn }: Props) => {
    return (
        <div className="mb-1 mt-2 flex items-center justify-between px-1">
            <SectionHeader label={label} className="p-1" />
            {isLoggedIn && (
                <FilterButton onClick={() => onClick(true)}>
                    <FunnelIcon />
                    <SectionHeader label="Filter" />
                </FilterButton>
            )}
        </div>
    )
}
