import { giphyBlue, giphyDarkestGrey, giphyRed, giphyWhite } from '@giphy/colors'
import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import Checkbox from 'ui/src/components/controls/checkbox'
import CloseIcon from 'ui/src/icons/close-icon'

export const Modal = styled.div`
    position: fixed;
    width: 546px;
    height: 667px;
    background-color: #212121;
    padding: 45px 35px 35px 35px;
    z-index: 9999999;
    left: calc(50% - 273px);
    top: calc(50% - 337px);
    -webkit-font-smoothing: antialiased;
    p {
        font-size: 14px;
        line-height: 18px;
        margin-top: 20px;
    }
    a {
        font-weight: bold;
        color: ${giphyBlue};
        &:hover {
            text-decoration: underline;
        }
    }
`

export const Close = styled(CloseIcon)`
    position: absolute;
    right: 20px;
    top: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;
`

export const StyledCheckbox = styled(Checkbox)`
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
`

export const CheckboxContainer = styled.label<{ stacked: boolean }>`
    display: ${(props) => (props.stacked ? 'block' : 'inline-block')};
    padding: ${(props) => (props.stacked ? '3px 0' : '0 35px 0 0')};
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: bold;
`

export const Heading = styled.div`
    color: rgb(207, 207, 207);
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
    display: flex;
    span {
        margin-left: 10px;
        color: rgb(82, 82, 82);
        font-size: 14px;
    }
`

export const Term = styled.div`
    color: ${giphyRed};
`

export const ReasonsList = styled.div`
    margin: 0px 0 20px;
`

export const AdditionalInfoInput = styled.textarea`
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #000;
    background-color: ${giphyWhite};
    padding: 10px;
    outline: none;
    margin-bottom: 25px;
    height: 68px;
    resize: none;
`

export const SubmitButton = styled(Button)`
    display: block;
    width: 100%;
    margin-bottom: 8px;
`

export const CancelButton = styled(Button)`
    display: block;
    width: 100%;
    background-color: #${giphyDarkestGrey};
`

export const ColorBar = styled.div`
    background: linear-gradient(63.434948822922024deg, #06ff99 0%, #6157ff 100%);
    height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`

export const Dim = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.8;
    z-index: 9999;
`

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 15px;
    a {
        color: ${giphyWhite};
        opacity: 0.75;
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
`
