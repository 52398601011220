'use client'
import { GridHeader } from '@/components/searchpage/grid-header'
import { SearchType } from '../types'
import UserContext from '@/context/user-context'
import { giphyUrl } from '@/app/util/env'
import { IChannel, IGif } from '@giphy/js-types'
import { Carousel, VideoOverlay } from '@giphy/react-components'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import ChannelAvatar from './channel-avatar'
import MobileRatingFilter from './filters/mobile-filter'
import SearchToggle from './search-toggle'
import { useGiphyFetch } from '@/app/util/use-gf'
import Koko from '../koko'
import { KokoResponse } from '../koko/api'
import { SectionHeader } from '@/components/sections'

export const HorizontalScroll = styled.div`
    display: flex;
    overflow: scroll;
    flex-direction: row;
    align-items: center;
    padding: 5px 0px;
`

type Props = {
    type: SearchType
    channels?: IChannel[]
    initialSearchTerm: string
    initialClips?: IGif[]
    clipWidth: number
    clipHeight: number
    windowWidth?: number
    typeString: (arg1: boolean) => void
    channelResponseId?: string
    kokoResponse?: KokoResponse
}

const MobileHeader = ({
    type: routerType,
    channels = [],
    initialSearchTerm,
    initialClips = [],
    clipWidth,
    clipHeight,
    typeString,
    channelResponseId,
    kokoResponse,
}: Props) => {
    const gf = useGiphyFetch()
    const { user } = useContext(UserContext)
    const [showRatingFilter, setShowRatingFilter] = useState<boolean>(false)
    return (
        <>
            <div className="mb-3 flex">
                <SearchToggle initialSearchTerm={initialSearchTerm} routerType={routerType} toggleWidth={`100%`} />
            </div>
            {!!channels.length && !!channelResponseId && (
                <HorizontalScroll>
                    {channels.map((channel: IChannel) => (
                        <ChannelAvatar key={channel.id} channel={channel} channelResponseId={channelResponseId} />
                    ))}
                </HorizontalScroll>
            )}
            <Koko preFetchedResponse={kokoResponse} term={initialSearchTerm} />
            {!!initialClips.length && (
                <>
                    <SectionHeader label="GIPHY Clips" className="mt-2" />
                    <Carousel
                        gifWidth={clipWidth}
                        gifHeight={clipHeight}
                        initialGifs={initialClips}
                        fetchGifs={(offset: number) =>
                            gf.search(initialSearchTerm, {
                                offset,
                                type: 'videos',
                                limit: 15,
                                rating: 'r',
                            })
                        }
                        onGifClick={(gif, e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            location.href = `${giphyUrl}${new URL(gif.url).pathname}`
                        }}
                        overlay={(props) => <VideoOverlay {...props} width={clipWidth} />}
                    />
                </>
            )}
            <GridHeader
                label={`All the ${typeString(routerType === 'gifs')}`}
                onClick={setShowRatingFilter}
                isLoggedIn={!!user}
            />
            {showRatingFilter && <MobileRatingFilter setShowRatingFilter={setShowRatingFilter} />}
        </>
    )
}

export default MobileHeader
