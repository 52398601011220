import { usePathname, useRouter, useSearchParams } from 'next/navigation'

export const useNextSearchParams = () => {
    const router = useRouter()
    const pathname = usePathname()
    const searchParams = useSearchParams()!

    const createQueryString = (name: string, value: string) => {
        const params = new URLSearchParams(searchParams)

        params.set(name, value)
        return params.toString()
    }

    const setSearchParams = (name: string, value: string) => {
        router.push(pathname + '?' + createQueryString(name, value))
    }

    return { searchParams, setSearchParams }
}
