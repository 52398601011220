import { giphyBlack, giphyLightCharcoal, giphyLightGrey } from '@giphy/colors'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { ControlSize, Gradient } from 'types'
import { colorFromGradientPosition } from 'utils'
import { textColorFromBackgroundColor } from '../../../utils/color'
import IconComponent from '../../icon'
import { Box, Check } from './style'
import { iconSizeFromControlSize } from './utilities'

type Props = {
    backgroundColor?: string
    checked?: boolean
    className?: string
    color?: string
    defaultChecked?: boolean
    disabled?: boolean
    gradient?: Gradient
    iconColor?: string
    name?: string
    outlineColor?: string
    size?: ControlSize
    style?: CSSProperties
    tabIndex?: number
    title?: string
    onChange?: (checked: boolean) => void
}

const Checkbox = ({
    backgroundColor = giphyBlack,
    checked: checkedProp,
    className,
    color = giphyLightGrey,
    disabled = false,
    defaultChecked = false,
    gradient,
    iconColor: iconColorProp,
    name,
    outlineColor = giphyLightCharcoal,
    size = 'small',
    style,
    tabIndex = 0,
    title,
    onChange,
}: Props) => {
    const initialCheckedRef = useRef<boolean | undefined>(
        typeof checkedProp !== 'undefined' ? checkedProp : defaultChecked
    )
    const [checked, setChecked] = useState<boolean>(initialCheckedRef.current || false)

    const baseColor = gradient ? colorFromGradientPosition(gradient, 0.5) : color
    const iconColor = iconColorProp || textColorFromBackgroundColor(baseColor)
    const iconSize = iconSizeFromControlSize(size)

    function onClick() {
        setChecked((state) => !state)
    }

    useEffect(() => {
        if (typeof checkedProp !== 'undefined') {
            setChecked(checkedProp)
        }
    }, [checkedProp])

    useEffect(() => {
        if (checked !== initialCheckedRef.current) {
            initialCheckedRef.current = undefined
            onChange?.(checked)
        }
    }, [checked])

    return (
        <Box
            $backgroundColor={backgroundColor}
            $disabled={disabled}
            $outlineColor={outlineColor}
            $size={size}
            className={className}
            style={style}
            title={title}
            tabIndex={tabIndex}
            type="button"
            onClick={onClick}
        >
            {checked && (
                <Check $color={color} $gradient={gradient}>
                    <IconComponent color={iconColor} icon="checkmark" height={iconSize} width={iconSize} />
                </Check>
            )}
            <input checked={checked} name={name} readOnly type="hidden" />
        </Box>
    )
}

export default Checkbox
