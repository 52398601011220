import { giphyLocalUrl } from '@/app/util/env'
import { timeoutFetch } from 'utils/src/api/fetch'

export const postData = async (url: string, data: any, headers: HeadersInit) => {
    const response = await timeoutFetch({
        url,
        options: {
            method: 'POST',
            headers: headers,
            referrerPolicy: 'strict-origin-when-cross-origin',
            body: data,
            credentials: 'same-origin',
        },
    })

    return response
}

type ReportSearchParams = {
    reasons: string
    additionalInfo: string
    responseId: string
    searchTerm: string
    contentType: string
    csrftoken: string
}

export const ReportSearchPost = async ({
    reasons,
    additionalInfo,
    responseId,
    searchTerm,
    contentType,
    csrftoken,
}: ReportSearchParams) => {
    const url = `${giphyLocalUrl}/ajax/report-search`
    const headers: HeadersInit = {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'X-CSRFToken': csrftoken,
        'X-Requested-With': 'XMLHttpRequest',
    }
    const data = JSON.stringify({
        reasons: reasons,
        additional_info: additionalInfo,
        search_response_id: responseId,
        search_term: searchTerm,
        search_content_type: contentType,
    })

    try {
        const response = await postData(url, data, headers)
        return response.ok
    } catch (error) {
        console.error('Error in ReportSearchPost:', error)
        return false
    }
}
