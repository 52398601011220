'use client'
import { FlashMessageContext } from '@/context/flash-message'
import { concat, without } from 'lodash'
import { ChangeEvent, SetStateAction, useContext, useState } from 'react'
import { useCookie } from 'react-use'
import { ReportSearchPost } from './api'
import {
    AdditionalInfoInput,
    CancelButton,
    CheckboxContainer,
    Close,
    ColorBar,
    Dim,
    Footer,
    Heading,
    Modal,
    ReasonsList,
    StyledCheckbox,
    SubmitButton,
    Term,
} from './style'

const SEARCH_INFO = 360019823451
const REPORT_GIF = 360020287371
const GUIDELINES = 360020286811
const DMCA = 360020287091

const REASONS_ORDER = [
    'NOT_RELEVANT',
    'NOT_FUN',
    'NOT_SAFE',
    'NOT_TIMELY',
    'NOT_ENOUGH_VERIFIED_CONTENT',
    'NOT_DIVERSE',
]

const REASONS_DESCRIPTION = {
    NOT_RELEVANT: 'Results are not relevant',
    NOT_FUN: 'Results are not very fun',
    NOT_SAFE: 'Results are unsafe',
    NOT_TIMELY: 'Results do not have the newest content',
    NOT_ENOUGH_VERIFIED_CONTENT: 'Results aren’t high quality',
    NOT_DIVERSE: 'Results are not diverse or lack representation',
}

const HelpCenterLink = ({ id, text }: { id: number; text: string }) => (
    <a href={`https://support.giphy.com/hc/en-us/articles/${id}`} target="_blank" rel="noreferrer">
        {text}
    </a>
)

type Props = {
    type: 'gifs' | 'stickers'
    gifResponseId: string
    clipResponseId: string
    searchTerm: string
    onClose: React.Dispatch<SetStateAction<boolean>>
}

const ReportSearch = ({ type, gifResponseId, clipResponseId, searchTerm, onClose = () => {} }: Props) => {
    const { showMessage } = useContext(FlashMessageContext)
    const [selectedReasons, setSelectedReasons] = useState<string[]>([])
    const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>([])
    const [additionalInfo, setAdditionalInfo] = useState('')
    const [csrf] = useCookie('csrftoken')
    const csrftoken = csrf || ''
    const submitClicked = async () => {
        let responseStatus
        for (const contentType of selectedContentTypes) {
            const responseId = contentType === 'clips' ? clipResponseId : gifResponseId
            responseStatus = await ReportSearchPost({
                reasons: selectedReasons.join(','),
                additionalInfo,
                responseId,
                searchTerm,
                contentType,
                csrftoken,
            })
        }
        showMessage({
            message: responseStatus ? `Successfully submitted report!` : `Error submitting report`,
            type: responseStatus ? 'success' : 'error',
        })
        onClose(false)
    }

    const closeClicked = () => {
        onClose(false)
    }
    return (
        <>
            <Dim onClick={closeClicked} />
            <Modal>
                <ColorBar />
                <div onClick={() => closeClicked()}>
                    <Close />
                </div>
                <Heading>
                    Why are you reporting the search for &quot;<Term>{searchTerm}</Term>&quot;?
                </Heading>
                <ReasonsList>
                    {REASONS_ORDER.map((key) => (
                        <CheckboxContainer stacked={true} key={key}>
                            <StyledCheckbox
                                checked={selectedReasons.includes(key)}
                                size="small"
                                onChange={() =>
                                    setSelectedReasons((prev) =>
                                        selectedReasons.includes(key) ? without(prev, key) : concat(prev, key)
                                    )
                                }
                            />
                            {REASONS_DESCRIPTION[key as keyof typeof REASONS_DESCRIPTION]}
                        </CheckboxContainer>
                    ))}
                </ReasonsList>
                <Heading>
                    What type of content are you reporting?
                    <span>Required</span>
                </Heading>
                <div className="mb-5">
                    <CheckboxContainer stacked={false}>
                        <StyledCheckbox
                            checked={selectedContentTypes.includes(type)}
                            size="small"
                            onChange={() => {
                                setSelectedContentTypes((prev) =>
                                    selectedContentTypes.includes(type)
                                        ? without(prev, type)
                                        : [...selectedContentTypes, type]
                                )
                            }}
                        />
                        {type === 'stickers' ? 'Stickers' : 'GIFs'}
                    </CheckboxContainer>
                    <CheckboxContainer stacked={false}>
                        <StyledCheckbox
                            checked={selectedContentTypes.includes('clips')}
                            size="small"
                            onChange={() => {
                                setSelectedContentTypes((prev) =>
                                    selectedContentTypes.includes('clips')
                                        ? without(prev, 'clips')
                                        : [...selectedContentTypes, 'clips']
                                )
                            }}
                        />
                        Clips
                    </CheckboxContainer>
                </div>
                <p>
                    Learn how to find the results you want and how these reports are used{' '}
                    <HelpCenterLink id={SEARCH_INFO} text={'here'} />.
                </p>
                <br />
                <Heading>
                    Any additional information?
                    <span>Required</span>
                </Heading>
                <AdditionalInfoInput
                    value={additionalInfo}
                    onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => setAdditionalInfo(evt.target.value)}
                />
                <>
                    <SubmitButton
                        onClick={submitClicked}
                        disabled={!selectedContentTypes.length || !additionalInfo}
                        gradient={selectedContentTypes.length && additionalInfo ? 'purple-indigo' : undefined}
                    >
                        Submit
                    </SubmitButton>
                    <CancelButton onClick={closeClicked}>Cancel</CancelButton>
                </>
                <Footer>
                    <HelpCenterLink id={REPORT_GIF} text={'Report an Inappropriate GIF '} /> |{' '}
                    <HelpCenterLink id={DMCA} text={'DMCA Copyright Policy'} /> |
                    <HelpCenterLink id={GUIDELINES} text={'Community Guidelines '} />
                </Footer>
            </Modal>
        </>
    )
}

export default ReportSearch
