'use client'
import styled from 'styled-components'
import Radio from 'ui/src/components/controls/radio'
import { useNextSearchParams } from '@/util/search-params'
import { DropdownContainer, DropdownChoice } from './style'

const ratingOptions = ['pg-13', 'r']

const Container = styled(DropdownContainer)`
    width: 250px;
    right: 0;
    top: 54px;
`

type RatingProps = { value: string; onChange: () => void; isChecked: boolean }

const Rating = ({ value, onChange, isChecked }: RatingProps) => {
    return (
        <DropdownChoice>
            <span>{`${value === 'pg-13' ? 'Hide' : 'Show'} Explicit Results`}</span>
            <Radio group="sort" value={value} onChange={onChange} isChecked={isChecked} />
        </DropdownChoice>
    )
}

const RatingDropdown = () => {
    const { searchParams, setSearchParams } = useNextSearchParams()
    const rating = searchParams.get('rating') || 'pg-13'
    return (
        <Container>
            Safe Search Filters{' '}
            <p>When explicit results are shown, you may see more sexualized, offensive, or violent content.</p>
            {ratingOptions.map((val) => (
                <Rating
                    key={val}
                    value={val}
                    isChecked={rating === val}
                    onChange={() => setSearchParams('rating', val)}
                />
            ))}
        </Container>
    )
}

export default RatingDropdown
