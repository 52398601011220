'use client'
import { useNextSearchParams } from '@/util/search-params'
import styled from 'styled-components'
import Radio from 'ui/src/components/controls/radio'
import { DropdownChoice, DropdownContainer } from './style'

const sortOptions = ['relevant', 'recent']

const Container = styled(DropdownContainer)`
    width: 238px;
    left: -32px;
    top: 54px;
`

type SortProps = { value: string; onChange: () => void; isChecked: boolean }

const Sort = ({ value, onChange, isChecked }: SortProps) => {
    return (
        <DropdownChoice>
            <span>{`${value === 'relevant' ? 'Relevant' : 'Newest'}`}</span>
            <Radio group="sort" value={value} onChange={onChange} isChecked={isChecked} />
        </DropdownChoice>
    )
}

const SortDropdown = () => {
    const { searchParams, setSearchParams } = useNextSearchParams()
    const sort = searchParams.get('sort') || 'relevant'
    return (
        <Container>
            <span>Sort Content By</span>
            {sortOptions.map((val) => (
                <Sort key={val} value={val} isChecked={sort === val} onChange={() => setSearchParams('sort', val)} />
            ))}
        </Container>
    )
}

export default SortDropdown
