'use client'
import { useState, useRef, ReactNode } from 'react'
import { useClickAway } from 'react-use'
import { Gradient } from 'types'
import { Container, Button } from './style'

type Props = {
    label: string
    icon: ReactNode
    iconGradient: Gradient
    children: ReactNode[] | ReactNode
    isDisabled?: boolean
}

const SearchFilter = ({ label, icon, iconGradient, children, isDisabled = false }: Props) => {
    const [showDropdown, setShowDropdown] = useState<Boolean>(false)
    const ref = useRef(null)
    useClickAway(ref, () => {
        setShowDropdown(false)
    })
    return (
        <Container ref={ref} isDisabled={isDisabled}>
            <Button
                size={'medium'}
                icon={icon}
                iconLocation={'left'}
                onClick={() => setShowDropdown(!showDropdown)}
                iconGradient={iconGradient}
            >
                {label}
            </Button>
            {showDropdown && <>{children}</>}
        </Container>
    )
}

export default SearchFilter
