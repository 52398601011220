import { ControlSize } from 'types'

export function borderSizeFromControlSize(size: ControlSize): number {
    switch (size) {
        case 'x-large':
        case 'large':
            return 3
        default:
            return 2
    }
}

export function iconSizeFromControlSize(size: ControlSize): number {
    switch (size) {
        case 'x-large':
            return 24
        case 'large':
            return 22
        case 'medium':
            return 18
        case 'small':
            return 15
        case 'mini':
            return 12
    }
}

export function sizeFromControlSize(size: ControlSize): number {
    switch (size) {
        case 'x-large':
            return 34
        case 'large':
            return 30
        case 'medium':
            return 24
        case 'small':
            return 20
        case 'mini':
            return 16
    }
}
