'use client'
import { SearchType } from '../types'
import { trackPageView } from '@/util/analytics'
import { useRouter } from 'next/navigation'
import { useContext, useEffect } from 'react'
import Toggle from 'ui/src/components/controls/toggle'
import { SearchTypeContext } from '../media-type-context'
import { getPathname } from './contents'

type Props = { toggleWidth: string | number; initialSearchTerm: string; routerType: SearchType }

const SearchToggle = ({ toggleWidth, initialSearchTerm, routerType }: Props) => {
    const router = useRouter()
    const { setSearchType: setType, searchType: lastType } = useContext(SearchTypeContext)
    useEffect(() => {
        setType(routerType)
    }, [lastType, setType, routerType])
    const toggleType = lastType || routerType
    return (
        <Toggle
            dontManageState
            gradientOff="purple-cyan"
            gradientOn="blue-green"
            labelOff="GIFs"
            labelOn="Stickers"
            isOn={toggleType !== 'gifs'}
            backgroundColor="transparent"
            style={{ width: toggleWidth }}
            onChange={(isOn: boolean) => {
                const nextType = isOn ? 'stickers' : 'gifs'
                const nextPathname = getPathname(`/search`, initialSearchTerm, nextType === 'stickers')
                setTimeout(() => {
                    trackPageView(nextPathname)
                }, 50)
                router.replace(nextPathname)
            }}
        />
    )
}
export default SearchToggle
