'use client'
import { useGiphyFetch } from '@/app/util/use-gf'
import MediumRectangleAd from '@/components/ads/medium-rectangle'
import Grid from '@/components/grid/grid'
import UAParserContext from '@/context/ua-parser'
import { IChannel, IGif } from '@giphy/js-types'
import { useSearchParams } from 'next/navigation'
import { useContext, useRef } from 'react'
import { KokoResponse } from '../koko/api'
import { SearchType } from '../types'
import DesktopHeader from './desktop-header'
import MobileHeader from './mobile-header'

const desktopClipWidth = 248
const mobileWidth = 161
const aspectRatio = 16 / 9

export const getPathname = (route: string, term: string, isStickerMode: boolean): string => {
    const gifUrl = `${term
        .replace(/stickers|sticker|transparent/gi, '')
        .trim()
        .replace(/\s+/g, '-')}`
    return `${route}/${gifUrl}${isStickerMode ? `-stickers` : ``}${document.location.search}`
}

const getTrackingUrl = (page: number, term: string) => `/search/${term}/${page}`

type Props = {
    term: string
    initialGifs?: IGif[]
    totalCount?: number
    initialClips?: IGif[]
    channels?: IChannel[]
    gifResponseId?: string
    clipResponseId?: string
    channelResponseId?: string
    type: SearchType
    kokoResponse?: KokoResponse
}
const Contents = ({
    term,
    initialGifs,
    totalCount,
    initialClips,
    channels,
    gifResponseId,
    clipResponseId,
    channelResponseId,
    type,
    kokoResponse,
}: Props) => {
    const { current: initialSearchTerm } = useRef(term)
    const { deviceType } = useContext(UAParserContext)
    const params = useSearchParams()
    const gf = useGiphyFetch()
    function setClipDimensions() {
        const clipWidth = deviceType === 'desktop' ? desktopClipWidth : mobileWidth
        return { clipWidth, clipHeight: clipWidth / aspectRatio }
    }
    const { clipWidth, clipHeight } = setClipDimensions()
    const typeString = (isGifs: boolean) => (isGifs ? 'GIFs' : 'Stickers')
    return (
        <>
            {deviceType === 'desktop' ? (
                <>
                    <MediumRectangleAd />
                    <DesktopHeader
                        term={term}
                        gifCount={totalCount}
                        type={type}
                        channels={channels}
                        initialSearchTerm={initialSearchTerm}
                        initialClips={initialClips}
                        clipWidth={clipWidth}
                        clipHeight={clipHeight}
                        gifResponseId={gifResponseId}
                        clipResponseId={clipResponseId}
                        channelResponseId={channelResponseId}
                        kokoResponse={kokoResponse}
                    />
                </>
            ) : (
                <MobileHeader
                    type={type}
                    channels={channels}
                    initialSearchTerm={initialSearchTerm}
                    initialClips={initialClips}
                    clipWidth={clipWidth}
                    clipHeight={clipHeight}
                    typeString={typeString}
                    channelResponseId={channelResponseId}
                    kokoResponse={kokoResponse}
                />
            )}
            <Grid
                initialGifs={initialGifs}
                key={`${params?.toString()}`}
                fetchGifs={async (offset: number) =>
                    gf.search(initialSearchTerm, {
                        offset,
                        limit: 15,
                        type: type,
                    })
                }
                gaTrackingUrl={(page) => getTrackingUrl(page, initialSearchTerm)}
                noShowMessage={
                    <>
                        No {typeString(type === 'gifs')} found for {initialSearchTerm}.<br />
                        Try searching for {typeString(type !== 'gifs')} instead?
                    </>
                }
            />
        </>
    )
}
export default Contents
