'use client'
import { useEffect, useState, SetStateAction } from 'react'
import Radio from 'ui/src/components/controls/radio'
import Modal from 'ui/src/components/modal'
import Close from 'ui/src/icons/close-icon'
import { useNextSearchParams } from '@/util/search-params'
import { RatingChoice, MobileContainer, CloseButton } from './style'

const RATING_OPTIONS = ['pg-13', 'r']

type RatingProps = {
    value: string
    onChange: (event: any) => void
    isChecked: boolean
}

const RatingOption = ({ value, onChange, isChecked }: RatingProps) => {
    return (
        <RatingChoice>
            <span>{`${value === 'pg-13' ? 'Hide' : 'Show'} Explicit Results`}</span>
            <Radio group="ratings" value={value} onChange={onChange} isChecked={isChecked}></Radio>
        </RatingChoice>
    )
}

type Props = {
    setShowRatingFilter: React.Dispatch<SetStateAction<boolean>>
}

const MobileRatingFilter = ({ setShowRatingFilter }: Props) => {
    const { searchParams, setSearchParams } = useNextSearchParams()
    const [rating, setRating] = useState<string>()

    useEffect(() => {
        setRating(searchParams.get('rating') || 'pg-13')
    }, [searchParams])

    return (
        <Modal>
            <MobileContainer>
                <h2>Safe Search Filters</h2>
                <p>When explicit results are shown, you may see more sexualized, offensive, or violent content.</p>
                {RATING_OPTIONS.map((val, index) => (
                    <RatingOption
                        key={index}
                        value={val}
                        onChange={() => {
                            setSearchParams('rating', val)
                        }}
                        isChecked={val === rating}
                    />
                ))}
                <CloseButton onClick={() => setShowRatingFilter(false)}>
                    <Close />
                </CloseButton>
            </MobileContainer>
        </Modal>
    )
}

export default MobileRatingFilter
