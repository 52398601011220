'use client'
import { IProfileUser } from '@giphy/js-types'
import { Container, Content, Title, ContentDescription, Description, IconContainer } from './style'
import Avatar from '../avatar'
import { VerifiedBadgeIcon } from '../../icons/ui'

type AvatarBadgeProps = {
    user: Partial<IProfileUser>
    className?: string
}

const AvatarBadge = ({ user, className = '' }: AvatarBadgeProps) => {
    return (
        <Container className={className}>
            <Avatar user={user} />
            <Content>
                <Title>{user.display_name || user.username}</Title>
                <ContentDescription>
                    <Description>{user.username}</Description>
                    <IconContainer>
                        <VerifiedBadgeIcon />
                    </IconContainer>
                </ContentDescription>
            </Content>
        </Container>
    )
}

export default AvatarBadge
