import { giphyYellow } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { ControlSize, Gradient } from 'types'
import { borderRadiusFromControlSize, gradientToCss } from 'utils'
import { borderSizeFromControlSize, sizeFromControlSize } from './utilities'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

export const Box = styled.button<{
    $backgroundColor: string
    $disabled: boolean
    $outlineColor: string
    $size: ControlSize
}>`
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    position: relative;
    user-select: none;

    &:active {
        opacity: 0.8;
    }

    &:focus-visible {
        outline: 2px solid ${giphyYellow};
    }

    ${({ $backgroundColor, $outlineColor, $size }) => css`
        background: ${$backgroundColor};
        border-radius: ${borderRadiusFromControlSize($size)}px;
        box-shadow: inset 0 0 0 ${borderSizeFromControlSize($size)}px ${$outlineColor};
        height: ${sizeFromControlSize($size)}px;
        width: ${sizeFromControlSize($size)}px;
    `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`

export const Check = styled.span<{ $color: string; $gradient?: Gradient }>`
    align-items: center;
    animation: ${fadeIn} 0.1s ease-in-out;
    border-radius: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    ${({ $color, $gradient }) => css`
        background: ${$gradient ? gradientToCss($gradient) : $color};
    `}
`
