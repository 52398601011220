import { giphyDarkestGrey, giphyIndigo, giphyLightGrey, giphyPurple } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import _Button from 'ui/src/components/controls/button'

export const dropdownDown = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`

export const Button = styled(_Button)`
    margin-left: 10px;
`

export const Container = styled.div<{ isDisabled?: boolean }>`
    height: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`

export const DropdownContainer = styled.div`
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: ${giphyDarkestGrey};
    padding: 20px 25px 10px;
    z-index: 998;
    animation: ${dropdownDown} 0.2s ease-in-out;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
    span {
        padding-bottom: 15px;
    }
    p {
        font-size: 14px;
        font-weight: normal;
        color: ${giphyLightGrey};
        margin: 5px 0 15px;
        line-height: 18px;
    }
`

export const DropdownChoice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 2px;
    span {
        line-height: 20px;
    }
`

/* Mobile Styles */

export const MobileContainer = styled.div`
    height: 300px;
    width: 100%;
    position: fixed;
    bottom: 0;
    border-radius: 10px 10px 0 0;
    padding: 30px 20px 40px;
    background: linear-gradient(${giphyIndigo}, ${giphyPurple});
    h2 {
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 20px;
    }
`

export const RatingChoice = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    span {
        line-height: 22px;
        font-weight: bold;
    }
`

export const CloseButton = styled.div`
    width: 20px;
    margin: auto;
    margin-top: 40px;
`
