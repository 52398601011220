'use client'
import UserContext from '@/context/user-context'
import { Interface, NexaBlack } from '@/styles/fonts'
import { appendParams, relativeGifClick } from '@/util/url'
import { giphyBlack, giphyLightGrey } from '@giphy/colors'
import { IChannel, IGif } from '@giphy/js-types'
import { GifOverlayProps, Gif as _Gif, VideoOverlay as _VideoOverlay } from '@giphy/react-components'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { Carousel as _Carousel } from 'ui'
import { FunnelIcon, SortIcon } from 'ui/src/icons/utility'
import Koko from '../koko'
import { KokoResponse } from '../koko/api'
import { SearchType } from '../types'
import ChannelAvatar from './channel-avatar'
import SearchOption from './filters'
import OpenInCurationStation from './filters/open-in-curation-station'
import RatingDropdown from './filters/rating'
import SortDropdown from './filters/sort'
import ReportSearch from './report-search'
import SearchToggle from './search-toggle'

const toggleWidth = 208

const Gif = styled(_Gif)`
    img {
        object-fit: cover;
    }
`

const VideoOverlay = styled(_VideoOverlay)`
    video {
        background: ${giphyBlack};
        object-fit: contain;
    }
`

const UICarousel = styled(_Carousel)`
    margin: 20px 0;
    h2 {
        padding: 0;
    }
`

const Container = styled.div`
    height: 61px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const LeftSide = styled.div`
    height: auto;
    display: flex;
    align-items: center;
    font-size: 46px;
    font-family: ${NexaBlack.style.fontFamily};
`

const NumberDisplay = styled.span`
    color: ${giphyLightGrey};
    padding-left: 6px;
    margin-top: 17px;
    font-size: 14px;
    font-family: ${Interface.style.fontFamily};
    font-weight: 700;
`

const RightSide = styled.div`
    display: flex;
`

const ReportSearchButton = styled.div`
    height: 20px;
    width: 20px;
    background-color: #2e2e2e;
    font-size: 10px;
    text-align: center;
    margin: 10px 6px 0 12px;
    cursor: pointer;
    &:hover {
        background-color: #5c5c5c;
    }
`

type Props = {
    term: string
    gifCount?: number
    type: SearchType
    channels?: IChannel[]
    initialSearchTerm: string
    initialClips?: IGif[]
    clipWidth: number
    clipHeight: number
    gifResponseId?: string
    clipResponseId?: string
    channelResponseId?: string
    kokoResponse?: KokoResponse
}

const DesktopHeader = ({
    term,
    gifCount,
    type,
    channels = [],
    initialSearchTerm,
    initialClips = [],
    clipWidth,
    clipHeight,
    gifResponseId,
    clipResponseId,
    channelResponseId = '',
    kokoResponse,
}: Props) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const { user } = useContext(UserContext)

    const formatLink = (term: string, type: string) => {
        const stickers = type === 'stickers' || type === 'Sticker' ? 'ONLY' : ''
        const formatUrl = new URL(`${location.origin}/edit/gifs`)
        const params: { [key: string]: string } = {
            publicSearch: 'Yes',
            type: 'search',
            query: term,
        }
        if (stickers) {
            params['stickers'] = stickers
        }
        const url = appendParams(formatUrl, params)
        return url.toString()
    }

    return (
        <>
            {showModal && (
                <ReportSearch
                    type={type as 'stickers' | 'gifs'}
                    searchTerm={term}
                    onClose={setShowModal}
                    gifResponseId={gifResponseId!}
                    clipResponseId={clipResponseId!}
                />
            )}
            <Container>
                <LeftSide>
                    {decodeURIComponent(term)}
                    {!!user && !!gifResponseId && !!clipResponseId && (
                        <ReportSearchButton className={'ss-flag'} onClick={() => setShowModal(true)} />
                    )}
                    {!!gifCount && (
                        <NumberDisplay>
                            {gifCount.toLocaleString()}{' '}
                            {` ${type === 'stickers' ? 'Sticker' : 'GIF'}${gifCount === 1 ? '' : 's'}`}
                        </NumberDisplay>
                    )}
                </LeftSide>
                <RightSide>
                    {user?.is_staff && <OpenInCurationStation label={'Open in CS'} href={formatLink(term, type)} />}
                    <SearchOption label={'Sort'} icon={<SortIcon />} iconGradient={'purple-indigo'}>
                        <SortDropdown />
                    </SearchOption>
                    <SearchOption
                        label={'Filter'}
                        icon={<FunnelIcon />}
                        iconGradient={'indigo-blue'}
                        isDisabled={!user}
                    >
                        <RatingDropdown />
                    </SearchOption>
                </RightSide>
            </Container>
            {!!channels.length && (
                <UICarousel title={`${channels.length} channels`}>
                    {channels.map((channel: IChannel) => (
                        <ChannelAvatar key={channel.id} channel={channel} channelResponseId={channelResponseId} />
                    ))}
                </UICarousel>
            )}
            <Koko preFetchedResponse={kokoResponse} term={term} />
            {!!initialClips.length && (
                <UICarousel title={`GIPHY Clips`}>
                    {initialClips.map((gif: IGif) => (
                        <Gif
                            key={gif.id}
                            overlay={(props: GifOverlayProps) => <VideoOverlay {...props} width={clipWidth} />}
                            gif={gif}
                            width={clipWidth}
                            height={clipHeight}
                            onGifClick={(gif, event) => {
                                event.stopPropagation()
                                event.preventDefault()
                                relativeGifClick(gif, event)
                            }}
                        />
                    ))}
                </UICarousel>
            )}
            <div className="mb-[5px] flex">
                <SearchToggle initialSearchTerm={initialSearchTerm} routerType={type} toggleWidth={toggleWidth} />
            </div>
        </>
    )
}

export default DesktopHeader
