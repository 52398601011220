'use client'
import { useContext } from 'react'
import { IChannel, IProfileUser } from '@giphy/js-types'
import { InView } from 'react-intersection-observer'
import { pingback } from '@giphy/js-analytics'
import { AvatarBadge } from 'ui'
import UserContext from '@/context/user-context'

type Props = {
    channel: IChannel
    channelResponseId: string
}

const ChannelAvatar = ({ channel, channelResponseId }: Props) => {
    const { user } = useContext(UserContext)
    return (
        <InView
            as="span"
            key={channel.id}
            triggerOnce
            threshold={1}
            onChange={(inView) => {
                if (inView) {
                    pingback({
                        userId: user ? user.id : undefined,
                        actionType: 'SEEN',
                        attributes: {
                            search_response_id: channelResponseId,
                        },
                        analyticsResponsePayload: channel.analytics_response_payload,
                    })
                }
            }}
        >
            <a
                href={(channel?.user as IProfileUser).profile_url}
                onClick={(e) => {
                    e.preventDefault()
                    pingback({
                        userId: user ? user.id : undefined,
                        actionType: 'CLICK',
                        attributes: {
                            search_response_id: channelResponseId,
                        },
                        analyticsResponsePayload: channel.analytics_response_payload,
                    })
                    window.location.href = (channel?.user as IProfileUser).profile_url
                }}
            >
                <AvatarBadge key={channel.id} user={channel.user} />
            </a>
        </InView>
    )
}

export default ChannelAvatar
